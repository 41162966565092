.ListFilter {
	> * + *{
		margin-top: 1rem;
	}
	.Search {
		padding: 2rem 2rem;
	}
	.checkbox-group {
		display: flex;
		flex-direction: row;

		.filter {
			border: 1px solid gray;
			border-radius: 11px;

			& + .filter {
				margin-left: 1rem;
			}

			&.checked {
				background: #0d181c;
				color: white;
			}
		}
	}
}
