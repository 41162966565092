@import './../../variables.scss';

.Suppliers {
	font-family: 'Oswald', sans-serif;
	max-width: 120rem;
	width: 100%;
	margin-bottom: 8rem;
	padding: 0 2rem;

	h2{
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
		font-weight: 400;
		margin: 8rem 0 0 0;
		padding: 0;
		font-size: 3.6rem;
		color: #333333;
	}

	.ListFilter {
		margin: 2rem 0 4rem;
	}
}
