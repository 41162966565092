.SubmissionSection {
	border: 1px solid #D8D8D8;
	padding: 1rem 1.5rem;
	border-radius: 0.6rem;
	background-color: #FCFCFC;
	margin-bottom: 2rem;
	--spacing: 1.5rem;
	--spacing-small: 0.5rem;

	.typeHeader {

		display: flex;
		flex-direction: column;

		h3 {
			text-transform: uppercase;
			font-size: 2rem;
			font-family: 'Oswald', sans-serif;
			font-weight: 400;
			display: flex;
			align-items: center;
			margin: 0;
			line-height: 33px;

			.ArchiveLink {
				margin-left: 1rem;
			}
		}
	}
	
	.Submission{
		margin-top: var(--spacing);
		margin-bottom: var(--spacing-small);
		display: flex;

		padding-bottom: 1rem;
		border-bottom: 1px solid #EAEAEA;
		margin-bottom: 1rem;
		
		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}

	}

	& > .Button.text-only{
		margin-top: var(--spacing);
		background-color: #eeeeee;
		color: #3b3b3b;

		&:hover{
			background-color: #dddddd;
		}

		.content {
			display: flex;
			align-items: center;
		}

		svg {
			margin-right: 1rem;
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}
