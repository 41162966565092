.ArchiveLink {
	border-radius: 1rem;
	font-size: 1.5rem;
	color: darkgray;
	transition: .4s;
	&:visited{
		color: darkgray;
	}
	.text{
		margin-left: 1rem;
		display: none;
		padding: 0 1rem 0 0;
	}
	&:hover{
		background: rgba(0,0,0,.05);
		color: #8d8d8d;
		.text{
			display: inline-block;
		}
	}
	.icon{
		background: rgba(0,0,0,.05);
		border-radius: 1rem;
		padding: .6rem 1rem;
	}



}
