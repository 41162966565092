@import './../../../variables.scss';

$checkmark-width: 1rem;
$checkmark-height: 2rem;

.TechnicalDetail {
	padding: 0 2rem;
	font-family: 'Oswald', sans-serif;
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	position: relative;
	h2 {
		text-transform: uppercase;
		font-size: 3rem;
	}
	section{
		border: 1px solid #D8D8D8;
		padding: 1rem 1.5rem;
		border-radius: 0.6rem;
		background-color: #FCFCFC;
		margin-bottom: 2rem;

		h3 {
			margin: 0;
		}
	}

	.form-errors {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		.error {
			display: flex;
			align-items: center;
			margin: 0 0 1rem 0;
			padding: 1rem 3rem 1rem 1rem;
			border: 1px solid $color-red;

			.error__dismiss {
				margin: 0 1rem 0 0;
				border: none;
				background-color: transparent;
				cursor: pointer;
				color: $color-red;
				font-weight: 600;
			}

			.error__message {
				font-size: 1.4rem;
				color: darken($color-red, 20);
			}
		}
	}

	.terms {
		label {
			padding: 0 0 0 1rem;
			font-weight: 300;
			font-size: 1.3rem;

			a {
				text-decoration: underline;
				font-weight: 400;
			}
		}
	}

}
