@import './../../index.scss';

.PortalModal {
	font-size: 1.6rem;

	.modal__text {
		padding: 1rem 1rem 2rem 1rem;
	}
}

.Sublogin {
	width: 60rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 2rem 0 0 0;

	.account__sections {
		width: 100%;
		margin-bottom: 2rem;

		h2 {
			color: #373735;
			font-size: 2.8rem;
			margin: 2rem 0 1rem 0;
			padding: 0;
			text-transform: uppercase;
		}
	}

	.account__section {
		background-color: #F4F4F4;
		border-radius: 1.2rem;
		width: 100%;
		padding: 1.4rem;
		
		.section__field {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 1rem;

			&:last-of-type {
				margin-bottom: 0;
			}

			.field__name {
				font-family: 'Oswald', sans-serif;
				text-transform: uppercase;
				font-size: 1.4rem;
				font-weight: 400;
				display: flex;
				align-items: flex-start;
				justify-content: flex-end;
				color: rgba(#333, 0.87);
			}

			.field__value {
				font-family: 'Open Sans', sans-serif;
				font-size: 1.6rem;
			}
		}
	}

	.sublogin__actions {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		width: 100%;
		
		.Button {
			margin-right: 1rem;
		}
	}

	.Button {
		.content {
			display: flex;
			align-items: center;

			.arrow {
				margin-right: 1rem;
			}
		}
	}

	h1 {
		font-size: 3.6rem;
		text-transform: uppercase;
		color: #333;
		border-bottom: 0.3rem solid #C1AD76;
		margin: 0 0 2rem 0;
		padding: 0;
	}

	.DynamicForm {
		.form__section {
			h2 {
				color: #373735;
				font-size: 2.8rem;
				margin-bottom: 1rem;
			}
		}

		.section__fields {
			background-color: #F4F4F4;
			border-radius: 1.2rem;

			.form__field {
				background-color: transparent;
				margin: 0;

				label, .PermissionsInput .permissions__groups h4 {
					margin: 0 0 0.5rem 0.5rem;
				}
			}
		}
	}

	.Button {

		.content {
			display: flex;
			align-items: center;

			svg {
				margin-right: 1rem;
			}
		}
	}

	.error, .submit__error {
		font-family: 'Open Sans', sans-serif;
		font-size: 1.6rem;
		display: flex;
		align-items: center;
		margin-bottom: 2rem;
		color: #c2092b;

		svg {
			margin-right: 1rem;
		}
	}
}
