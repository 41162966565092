.Alerts {
	width: 100%;
	position: relative;
	z-index: 9;

	.Alert {
		height: 4.8rem;
		background-color: #21211F;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.4rem;
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
		//font-weight: 600;
	}

	button.link {
		border: none;
		background-color: transparent;
		font-size: 1.4rem;
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
		color: white;
		text-decoration: underline;
		cursor: pointer;
	}

	.VersionAlert {
		svg {
			color: #c2092b;
			margin-right: 1rem;
		}

		.Spinner .circle {
			border-color: white;
			border-top-color: transparent;
			border-left-color: transparent;
		}
	}
}