.SaveButton {
	margin: 2rem 0;


	.Button {
		font-size: 2rem;
		width: 100%;
		z-index: 999;
		svg{
			margin-right: 1rem;
		}
		.Spinner{
			--color: #ffffff;
		}
		&.fail{
			animation: 1s shake;
		}
		&[disabled] {
			opacity: 1;
			--color: #bcbcbc;
			cursor: not-allowed;
			box-shadow: none;
		}
	}

	ul {
		padding: .2rem 1rem;

		li {
			list-style-type: none;
			width: 100%;
			font-size: 1.2rem;
			color: red;
			text-transform: uppercase;
		}
	}
}

@keyframes shake {
	0% {
		transform: translateX(0);
	}
	20% {
		transform: translateX(-3px);
	}
	30% {
		transform: translateX(5px);
	}
	40% {
		transform: translateX(-8px);
	}
	50% {
		transform: translateX(8px);
	}
	60% {
		transform: translateX(-5px);
	}
	70% {
		transform: translateX(3px);
	}
	80% {
		transform: translateX(0);
	}
}
