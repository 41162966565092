@import './variables.scss';

* {
	box-sizing: border-box;
}

html {
	font-size: 10px;
	margin: 0;
}

body {
	margin: 0;
	font-family: $font-main;
	//width: 100vw;
	overflow-x: hidden;
	background-color: $color-background;
}

a {
	color: inherit;
	text-decoration: inherit;

	&:visited {
		color: inherit;
	}
}

ul {
	padding: 0;
	margin: 0;
}
