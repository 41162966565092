.Technical {
	font-family: 'Oswald', sans-serif;
	max-width: 120rem;
	width: 100%;
	margin-bottom: 8rem;
	padding: 0 2rem;

	h2, h3 {
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
		font-weight: 400;
		margin: 0;
		padding: 0;
	}

	h2 {
		font-size: 3.6rem;
		color: #333333;
		margin-top: 8rem;
	}

	h3 {
		font-size: 2.4rem;
		color: #858585;
		margin-top: -1rem;
	}

	.ListFilter {
		margin: 2rem 0 4rem;
	}

	.message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 2.4rem;
		font-weight: 300;

		span[role="img"] {
			font-size: 4rem;
		}
	}

	.List {
		.list__header{
			font-size: 2rem;
			.header_group{
				position: relative;
				&:before{
					content: attr(data-group);
					width: 15rem;
					height: 2rem;
					font-size: 1.4rem;
					position: absolute;
					top: -2rem;
					left: 0;
				}
			}
			.header_item{
				cursor: pointer;
				white-space: nowrap;
				+ .header_item{
					margin-left: 2rem;
				}
				&:hover{
					svg{
						color: rgba(0,0,0,.6);
					}
				}
				svg{
					margin-left: 1rem;
				}
			}

		}

		.list__body {
			.list__item {
				color: #707070;
				text-transform: uppercase;
				font-weight: 400;
			}
			.siteCode{
				display: inline-block;
				width: 7.5rem;
				+ .siteCode{
					margin-left: 2rem;
				}
			}
			.img {
				width: 5rem;
				height: 5rem;
				margin: -1rem 0 -1rem -1rem;
				border-radius: .5rem;
				overflow: hidden;
				img{
					width: 5rem;
					height: 5rem;
					object-fit: cover;
				}
			}

			.name {
				color: #333333;
			}

			.status {
				display: flex;
				justify-content: center;
				margin: -0.5rem -1rem -0.5rem 0;

				.Button {
					width: 19rem;
					height: 4.8rem;

					.content {
						display: flex;
						align-items: center;
						justify-content: flex-start;

						.circle {
							background-color: white;
							width: 1rem;
							height: 1rem;
							border-radius: 1rem;
							margin-right: 1rem;

							&.status--NEW {
								background-color: #ec5c5c;
							}

							&.status--UNAPPROVED {
								background-color: #f3af48;
							}

							&.status--APPROVED {
								background-color: #1c9462;
							}
						}
					}
				}
			}
		}
	}

	.excelPanel {
		h3 {
			text-transform: none;
			font-size: 2rem;
		}
		.Button {
			margin-top: 3rem;
		}
		.FileUpload {
			margin-top: 3rem;
			margin-left: 2rem;
		}
	}

	.products__introduction{
		font-weight: 300;
		border: 1px solid black;
		border-radius: 1rem;
		padding: 1rem 4rem;
		margin: 5rem;
		font-size: 1.7rem;
		box-shadow: .5rem .5rem 2rem 2rem rgba(0,0,0,.05);
	}
}
