.Submission {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;

	// ensure an even margin in horizontal and vertical layout without affecting layout
	margin: -.5rem;

	.ValueSection {
		margin: .5rem;
	}

	.StatusSection {
		margin: .5rem;
	}
}
