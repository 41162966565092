@import './../../variables';

.Auth {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	background-color: white;

	.charles-logo {
		margin-top: -12rem;
		height: 10rem;
	}

	.auth-form {
		background-color: white;
		box-shadow: $box-shadow-1;
		padding: 3rem 3rem 2.5rem 3rem;
		margin: 2rem 0 0 0;
		width: 50rem;
		display: flex;
		flex-direction: column;
		position: relative;
		border-radius: 3rem;

		h1 {
			text-align: center;
			text-transform: uppercase;
		}

		p {
			font-size: 1.6rem;
		}

		.form {
			display: flex;
			flex-direction: column;
			label{
				display: flex;
				flex-direction: column;
				text-indent: 2rem;
				text-transform: uppercase;
				font-size: 1.6rem;
				color: rgb(108, 117, 125);
				font-family: $font-heading;
			}
			input {
				padding: 1rem 2rem;
				border: 1px solid rgba(236, 236, 236, 1);
				border-radius: 2.5rem;
				font-size: 2rem;
				margin: 0 0 2rem 0;
				font-family: 'Oswald', sans-serif;
				background-color: transparent;
				transition: 0.3s $cubic-bezier-1 all;
			}

			ul.form__errors {
				list-style: none;
				font-size: 1.4rem;
				color: $color-red;

				li {
					padding: 0 1rem;
					margin: 1rem 0;
					border-left: 0.3rem solid $color-red;
				}
			}

			button, input[type="submit"] {
				padding: 1rem 0;
				margin: 1rem 0 0 0;
				font-size: 2rem;
				border-radius: 3rem;
			}

			.email-sent-hint {
				text-align: center;
				font-size: 1.8rem;
				font-family: $font-heading;
				font-weight: 300;
			}
		}
	}

	.auth__locale {
		display: flex;
		justify-content: center;
		margin: 5rem 0 0 0;
		button {
			border: none;
			background-color: transparent;
			cursor: pointer;
			margin: 0 1rem 0 0;
			padding: 0;
			font-family: $font-heading;
			text-transform: uppercase;
			font-size: 1.6rem;
			font-weight: 600;
		}
	}
	.VersionNumber{
		display: flex;
		justify-content: center;
	}

	.link_to {
		position: absolute;
		bottom: -3.5rem;
		left: 0;
		right: 0;
		text-align: center;
		margin: 1rem 0 0 0;
		font-size: 1.2rem;
		text-decoration: underline;
		color: rgba($color-black-2, 0.4);
		&:hover{
			color: rgba($color-black-2, 0.6);
		}
	}
}
