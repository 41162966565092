html {
  font-size: 10px;
}

.Label {
  --color-r: 51;
  --color-g: 51;
  --color-b: 51;
  --color: rgb(var(--color-r), var(--color-g), var(--color-b));
  background-color: var(--color);
  border: none;
  border-radius: 1.2rem;
  color: white;
  padding: 1rem 2rem;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-size: 1.6rem;
  cursor: pointer;
  transition: 0.3s ease all;
  position: relative;
  text-align: center;
  &.decoration__red, &.decoration__orange, &.decoration__green {
    --decoration-color: white;
    --decoration-size: 1rem;
    padding-left: 3.5rem;

    &.decoration__red{
    --decoration-color: #EC5C5C;
  }
    &.decoration__orange{
      --decoration-color: #F3AF48;
    }
    &.decoration__green{
      --decoration-color: #1C9462;
    }

    &:after{
      width: var(--decoration-size);
      height: var(--decoration-size);
      border-radius: var(--decoration-size);
      position: absolute;
      background-color: var(--decoration-color);
      content: '';
      left: 1.5rem;
      top: 1.7rem;
    }
  }



  &.orange {
    --color-r: 255;
    --color-g: 159;
    --color-b: 28;

    --color: rgb(var(--color-r), var(--color-g), var(--color-b));
  }

  &.red {
    --color-r: 219;
    --color-g: 86;
    --color-b: 86;

    --color: rgb(var(--color-r), var(--color-g), var(--color-b));
  }

  &.border-only {
    border: 2px solid;
    box-shadow: none;
    background-color: white;
    color: var(--color);
    border-color: var(--color);
  }

  &.text-only {
    color: var(--color);
    box-shadow: none;
    background-color: transparent;

    .content {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: -2%;
        bottom: 1px;
        width: 104%;
        height: 1px;
        background-color: var(--color);
        transition: all 0.3s ease;
      }
    }

    &.no-underline .content:after {
      content: none;
    }
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;

    .content {
      &:after {
        //background-color: grey;
        bottom: calc(50% - 1px);
        height: 2px;
      }
    }

    &.text-only {
      //color: grey;
    }
  }
}
