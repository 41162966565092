.ValueSection {
	flex: 6;
	min-width: 650px;
	border-radius: 1rem;
	overflow: hidden;
	border: 1px solid lightgrey;

	&.status--0 {
		color: black;
		border: 2px solid #1483cf;
		background-color: white;
	}

	&.uploading{
		 border: none;
	}
	&.hasAction{
		opacity: .7;
		border: 1px solid #c84756;
	}
	&.deleting{
		opacity: .7;
		border: 2px solid #c84756;
	}


	& > * {
		width: 100%;
		vertical-align: middle;
	}
}


