.error__version {
	background-color: #21211F;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	.error__text {
		font-size: 2rem;
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
	}

	.error__hint {
		font-size: 1.6rem;
		font-family: 'Open Sans', sans-serif;
	}

	button.link {
		border: none;
		background-color: transparent;
		font-size: inherit;
		color: white;
		text-decoration: underline;
		cursor: pointer;
	}
}
