$color-black-2: #373735;
$cubic-bezier-1: cubic-bezier(0.4, 0.0, 0.2, 1);
$box-shadow-1: 0 0 1.5rem 0 rgba(0, 0, 0, .2);
$radius-default: 1.2rem;

.PortalModal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	z-index: 999;

	.modal__backdrop {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba($color-black-2, 0.5);
		animation: fade 0.2s $cubic-bezier-1 1;
		z-index: 1000;
	}

	.modal__body {
		z-index: 1000;
		background-color: white;
		padding: 1.2rem 3.5rem 1.2rem 1.2rem;
		box-shadow: $box-shadow-1;
		animation: grow 0.2s $cubic-bezier-1 1;
		position: relative;
		border-radius: $radius-default;
		overflow: hidden;

		.modal__close {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 2rem;
			color: $color-black-2;
			cursor: pointer;
			position: absolute;
			top: 0.2rem;
			right: 0.2rem;
			width: 3rem;
			height: 3rem;
			z-index: 99;
		}
	}

	&.minimal-padding {
		.modal__body {
			padding: 0;
		}
	}

	&.bare {
		.modal__body{
			padding: 0;
			background-color: transparent;
			box-shadow: none;
		}
	}
}

@media (max-width: 480px) {
	.PortalModal {
		z-index: 999;

		.modal__body {
			border-radius: 0;
			width: 100vw;
			height: 100vh;
			padding: 0;

			.modal__close {
				top: 0.5rem;
				right: 0.5rem;
				font-size: 3rem;
			}
		}
	}
}
