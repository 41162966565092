.ProductHeader {
	text-transform: uppercase;
	padding-top: 1rem;
	font-size: 3.6rem;

	span + span {
		margin-left: 2rem;
	}

	.id, .name {
		font-weight: 500;
	}

	.id {
		color: #858585;
	}

	.name {
		color: #333333;
		border-bottom: 0.3rem solid #C1AD76;
	}
}
