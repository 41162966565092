@import './../../../../variables.scss';

.Allergens {

	.no-allergens {
		margin: 0.5rem 0 0 0;
		&.display:not(.checked) {
			opacity: .5;
		}
	}

	.allergens {
		column-count: 2;
		margin-top: 2rem;

		.Checkbox {
			min-width: 20rem;
			padding: .6rem 0 0;
			display: flex;

			&.display:not(.checked) {
				opacity: .5;
			}

			label {
				break-inside: avoid;
				width: 100%;
			}
		}
	}
	&.disabled {
		.Checkbox:not(.checked){
			//display: none;
		}
	}
}

@media (max-width: 1000px) {
	.Allergens {
		.allergens {
			column-count: 1;
		}
	}
}
