@import '../../variables';

.Search {
	padding: 1rem 2rem;
	width: 100%;
	border: 1px solid #ececec;
	border-radius: 1.2rem;
	font-family: $font-heading;
	font-size: 2rem;
	color: $color-black-2;
	background-color: transparent;
	outline: none;
	-webkit-appearance: none;

	&::placeholder {
		color: rgba($color-black-2, 0.5);
	}
	&:focus{
		box-shadow: 0 .5rem 1.5rem 0 rgba(0,0,0,.2);
		border-color: transparent;
	}


}
