.StatusSection {
	flex: 4;
	min-width: 440px;
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	gap: 1rem;

	.reasons {
		margin: 1rem 2rem 2rem;
		font-family: 'Open Sans', sans-serif;
		font-size: 1.6rem;
		color: grey;
		li{
			white-space: pre-wrap;
		}
	}
	
	.Button.text-only{
		&.remove{
			display: grid;
			place-content: center;
			background-color: #e9e9e9;
			color: #737373;
			cursor: pointer;
			border-radius: 1rem;
			transition: .4s;
			svg{
				margin-right: 1.6rem;
			}
			&:hover{
				background-color: #c84756;
				color:white;
			}
		}
		.undo{
			display: grid;
			place-content: center;
			background-color: #e9e9e9;
			color: #3b3b3b;
			cursor: pointer;
			border-radius: 1rem;
			transition: .4s;
			svg{
				margin-right: 1.6rem;
			}
			&:hover{
				background-color: #c6c6c6;
			}
		}
	}

	.deleting-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 2rem;

		.info {
			margin-bottom: 1rem;
			font-family: 'Open Sans', sans-serif;
			font-size: 1.4rem;
			color: #CC0000;
		}
	}

	.save-fail-info{
		margin-top: 1rem;
		font-family: 'Open Sans', sans-serif;
		font-size: 1.4rem;
		color: #CC0000;
	}
}
