.CommercialDetail {
	padding: 0 2rem;
	font-family: 'Oswald', sans-serif;
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	position: relative;

	.category {
		margin: 4rem 0 1rem;

		h2 {
			text-transform: uppercase;
			font-size: 3rem;
		}

		p {
			font-size: 1.6rem;
			font-weight: 300;
		}

	}
	.SaveButton{
		z-index: 2;
		position: sticky;
		bottom: 2rem;

		&:after{
			content: '';
			position: absolute;
			bottom: -2rem;
			left: 0;
			right: 0;
			height: 8rem;
			background-color: white;
			z-index: 998;
		}
	}
}
