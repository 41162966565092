.ProductNavigator {
	display: flex;
	justify-content: space-between;
	margin: 2rem 0 0rem 0;

	.Button {
		.content {
			display: flex;
			align-items: center;
			justify-content: center;

			.arrow {
				&.arrow-left{
					margin-right: 1rem;
				}
				&.arrow-right{
					margin-left: 1rem;
					transform: scaleX(-1);
				}
			}
		}
	}
}
