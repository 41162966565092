.Archive {
	font-family: 'Oswald', sans-serif;
	margin: 0 auto 5rem;
	position: relative;
	padding: 0 2rem;
	width: 100%;
	max-width: 120rem;

	.subtitle{
		text-transform: uppercase;
		font-size: 3rem;
		padding: 0;
		margin: 0;
		span:first-of-type{
			font-weight: 300;
			color: gray;
		}
		span + span{
			margin-left: 1rem;
		}

	}
	.subtype{
		padding: 1.5rem;
		border-radius: 1.3rem;
		background-color: #f8f7f7;
		margin-top: 2rem;
	}
	.submission {
		display: flex;
		align-items: flex-start;
		position: relative;
		& + .submission{
			margin-top: 5rem;
			&:after{
				position: absolute;
				content: '';
				width: 2px;
				height: 3rem;
				margin: 1rem 2rem;
				top: -5rem;
				background-color: gray;
			}
		}

		.text{
			width: 55%;
			border: 1px solid lightgrey;
			padding: 1rem 1.6rem;
			border-radius: .7rem;
			font-family: 'Oswald', sans-serif;
			font-weight: 300;
			margin: 0 1rem 0 0;
			font-size: 1.6rem;
			white-space: pre-wrap;
		}
		.image{
			width: 55%;
			border: 1px solid lightgrey;
			border-radius: .7rem;
			margin: 0 1rem 0 0;
		}

		.StatusLabel + .StatusLabel{
			margin-top: 1rem;
		}
		.reasons {
			margin: 1rem 2rem 2rem;
			font-family: 'Open Sans', sans-serif;
			font-size: 1.6rem;
			color: grey;
			li{
				white-space: pre-wrap;
			}
		}

	}



	.noSubmissions{
		font-size: 2.5rem;
	}
}
