.Sublogins {
	max-width: 120rem;
	width: 100%;
	margin-bottom: 8rem;
	padding: 0 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	h2, h3 {
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
		font-weight: 400;
		margin: 0;
		padding: 0;
		width: 100%;

	}

	h2 {
		font-size: 3.6rem;
		color: #333333;
		margin-top: 8rem;
	}

	h3 {
		font-size: 2.4rem;
		color: #858585;
		margin-top: -1rem;
	}

	.message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 2.4rem;
		font-weight: 300;

		span[role="img"] {
			font-size: 4rem;
		}
	}

	.List {
		width: 100%;

		.disabled {
			color: #777777;

			svg {
				margin-right: 1rem;
			}
		}
	}

	.Button {
		.content {
			display: flex;
			align-items: center;

			svg {
				margin-right: 1rem;
			}
		}
	}

	.list__actions {
		margin: 2rem 0;
		width: 100%;
	}
}