@import '../../variables';

.SCheckbox {
	input {
		opacity: 0;
	}

	input + label {
		color: rgba($color-black-2, 0.8);
	}

	input:checked + label {
		color: rgba($color-black-2, 1);
	}

	input[disabled=""] + label {
		color: rgba($color-black-2, 0.5);
		cursor: default;
	}

	input[disabled=""]:checked + label {
		color: rgba($color-black-2, 0.75);
	}

	input:checked + label::before {
		background-color: $color-main-0;
		border-color: $color-main-0;
	}

	input[disabled=""] + label::before {
		content: none;
	}

	input + label::after {
		content: none;
	}

	input:checked + label::after {
		content: '';
	}

	input[disabled=""]:checked + label::after {
		border-color: $color-main-0;
	}

	label {
		position: relative;
		cursor: pointer;
		margin: 0 0 0 0;

		&::before, &::after {
			position: absolute;
		}

		&::before {
			content: '';
			display: inline-block;
			height: 1.4rem;
			width: 1.4rem;
			border: 2px solid $color-grey-1;
			left: -1.5rem;
			top: -0.1rem;
		}

		&::after {
			content: '';
			display: inline-block;
			height: 0.4rem;
			width: 0.9rem;
			border-left: 0.3rem solid;
			border-bottom: 0.3rem solid;
			border-color: white;
			transform: rotate(-50deg);
			left: -1.2rem;
			top: 0.3rem;
		}
	}

	&.radio {
		label::before {
			border-radius: 1rem;
		}

		input:checked + label::before {
			background-color: inherit;
		}

		label::after {
			background-color: $color-main-0;
			border: none;
			border-radius: 1rem;
			width: 0.8rem;
			height: 0.8rem;
			margin-top: 0.1rem;
			margin-left: 0.2rem;
		}
	}

	input:focus + label::before, input:hover + label::before {
		background-color: lighten($color-main-0, 25);
		border-color: $color-main-0;
	}

	input:checked:focus + label::before, input:checked:hover + label::before {
		background-color: lighten($color-main-0, 25);
	}

	input:checked:focus + label::after, input:checked:hover + label::after {
		border-color: $color-main-0;
	}

	input:hover + label::before {
		background-color: transparent;
	}

	input:checked:hover + label::before {
		background-color: transparent
	}
}
