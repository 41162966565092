@import './../../../../variables.scss';

.VvoDocs {
	.requirements {
		font-size: 1.4rem;
	}

	.prod-specs {
		.prod-spec {
			display: flex;
			flex-direction: column;
			+ .prod-spec{
				margin: 2rem 0 0 0;
			}

			.prod-spec__language {
				font-family: $font-heading;
				font-weight: 400;
				font-size: 2.2rem;
			}

			.prod-spec__file {
				font-size: 1.4rem;
				align-items: center;

				.file__remove {
					border: none;
					background-color: transparent;
					color: $color-red;
					font-size: 1.6rem;
					cursor: pointer;
					padding: 0;
					margin: 0 1rem 0 0;
				}

				.file__name {
					width: 100%;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					display: block;
					text-decoration: underline;
					font-size: 1.6rem;
					color: #454545;
					svg{
						margin-right: .7rem;
					}

					&.file__name--no-url {
						text-decoration: none;
					}
				}
				.file__dates {
					display: flex;
					flex-direction: column;
					&__upload {
						font-size: 1.4rem;
						color: #6c600e;
					}
					&__expired {
						font-size: 1.4rem;
						color: #6c600e;

						&__sos {
							color: #ce3f25;
						}
					}
					.checkbox__refresh {
						margin-top: 1rem;
						&__text {
							font-size: 1.4rem;
					}

					}

				}
			}

			.prod-spec__upload {
				width: 40rem;
			}

			.prod-spec__no-file {
				font-size: 1.8rem;
				font-family: $font-heading;
				font-weight: 300;
			}
		}
	}
}


@media (max-width: 1000px) {
	.VvoDocs {
		.prod-spec__upload {
			width: 100% !important;
		}
	}
}

