@import './../../variables.scss';

.Terms {
	padding: 4rem;
	background-color: white;
	box-shadow: $box-shadow-1;

	h1 {
		text-transform: uppercase;
	}

	p {
		font-size: 1.6rem;
		font-family: $font-main;
	}
}
