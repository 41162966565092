html {
	--width-nav: 32rem;
	--height-nav: 6rem;
	--width-nav-collapsed: 6rem;
}

// FONTS
$font-main: 'Open Sans', sans-serif;
$font-heading: Oswald, sans-serif;

// COLORS
$color-background: #ffffff;
$button-disabled: #CEC5AC;

$color-main-0: #C1AD76;
$color-main-1: #B9A264;

$color-black-0: #0d181c;
$color-black-1: #21211F;
$color-black-2: #373735;

$color-grey-0: #ececec;
$color-grey-1: #d3d3d3;

$color-red: rgba(206, 63, 37, 1);
$color-orange: rgba(248, 172, 37, 1);
$color-green: rgba(0, 177, 106, 1);

// DIMENSIONS
$content-side-margin: 5rem;

// BOX SHADOWS
$box-shadow-1: 0 0 1.5rem 0 rgba(0, 0, 0, .2);
$box-shadow-2: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, .2);

// OTHERS
$cubic-bezier-1: cubic-bezier(0.4, 0.0, 0.2, 1);

$break-point-1: 650px;
