@import './variables.scss';

.App {
	.loading{
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.errorTable{
		z-index:999;
		position:fixed;
		top:2rem;
		left:2rem;
		right:2rem;
		background-color: rgba(0,0,0,.8);
		padding: 2rem;
		border-radius: 1rem;
		backdrop-filter: blur(10px);
		color: white;
	}
	.app__environment {
		position: fixed;
		right: 1rem;
		bottom: 1rem;
		font-size: 1.4rem;
		font-family: $font-heading;
		text-transform: uppercase;
		color: white;
		background-color: $color-red;
		padding: 1rem 2rem;
		border-radius: 2.5rem;
	}

	.wrapper {
		width: 100%;
		min-height: 100vh;
		display: grid;
		grid-template-areas: 'nav main' 'nav main';
		grid-template-columns: var(--width-nav) auto;
		grid-template-rows: var(--height-nav) auto;

		.Navigation {
			grid-area: nav;
			position: fixed;
			.header__locale{
				width:100%;
				display: flex;
				padding: 1rem;
				.Button{
					flex-grow: 1;
				}
			}
		}

		main {
			grid-area: main;
			position: relative;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	&.nav--collapsed .wrapper{
		grid-template-columns: var(--width-nav-collapsed) auto;
	}



	h1 {
		font-family: $font-heading;
		font-weight: 400;
		margin: 0 0 1.6rem 0;
		font-size: 3.2rem;
	}

	h2 {
		font-family: $font-heading;
		font-weight: 400;
		width: 100%;
		padding: 0 0 1rem 0;
		margin: 0;
		font-size: 2.4rem;
	}

	h3 {
		font-family: $font-main;
		font-size: 1.4rem;
		margin: 1.2rem 0 0.6rem 0;
		padding: 0;
	}

	.button {
		text-transform: uppercase;
		border: 2px solid $color-black-2;
		padding: 1rem 2rem;
		cursor: pointer;
		background-color: white;
		color: $color-black-2;
		transition: 0.2s $cubic-bezier-1 all;
		font-family: $font-heading;
		font-weight: 400;
		font-size: 1.6rem;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		&:hover {
			background-color: $color-black-2;
			color: white;
			box-shadow: $box-shadow-1;
		}

		&.button--green {
			border-color: $color-green;
			border-left-width: 1rem;

			&:hover {
				background-color: $color-green;
			}

			&.disabled, &:disabled {
				cursor: inherit;
				opacity: 0.6;

				&:hover {
					color: inherit;
					box-shadow: none;
					background-color: white;
				}
			}
		}

		&.button--orange {
			border-color: $color-orange;
			border-left-width: 1rem;

			&:hover {
				background-color: $color-orange;
			}
		}

		&.button--red {
			border-color: $color-red;
			border-left-width: 1rem;

			&:hover {
				background-color: $color-red;
			}
		}

		&.button--alt {
			background-color: $color-main-0;
			border: none;

			&:hover {
				box-shadow: $box-shadow-1;
				color: $color-black-2;
				background-color: $color-main-1;
			}
		}

		&.disabled, &:disabled {
			cursor: inherit;
			opacity: 0.6;

			&:hover {
				color: inherit;
				box-shadow: none;
			}
		}
	}

}

::selection {
	background-color: rgba($color-black-2, 1);
	color: white;
}

.charles-toast {

	.charles-toast__body {
		padding: 0 1rem;
		color: $color-black-2;
		font-size: 1.4rem;
		font-family: $font-heading;
		font-weight: 300;
		max-width: 270px;
	}

	.charles-toast__progress {
		background: $color-main-0;
	}

	.Toastify__close-button {
		color: $color-black-2;
	}

	&.Toastify__toast--success {
		background-color: white;

		.charles-toast__progress {
			background: $color-green;
		}
	}

	&.Toastify__toast--error {
		background-color: white;
		border-left: 1rem solid $color-red;
	}
}

@media (max-width: $break-point-1) {
	.App {
		.wrapper {
			display: grid;
			grid-template-areas: 'nav nav' 'main main';
		}
	}
}
