.FileUpload {
	position: relative;
	cursor: pointer;
	&.buttonStyle{
		display: inline-block;
		input{
			z-index: -1;
		}
	}
	input{
		z-index: 999;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;

		opacity: 0;
		cursor: pointer;
	}

	.dropArea {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: .5rem;
		padding: 3.4rem;

		height: 138px;

		text-align: center;
		border: 2px dashed #C8CBCE;
		font-size: 1.8rem;

		color: #8d8484;
		text-transform: uppercase;
		cursor: pointer;

		&.loading{
			padding: 2rem;
		}

		&.dragover{
			border-color: #4d4d4d;
			cursor: copy;
		}

		.or {
			font-size: 1.4rem;
			margin: 1rem;
		}

		.Button {
			border-color: #8d8484;
			color: #8d8484;
			background: none;
			display: block;
		}
	}
}
