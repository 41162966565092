.StatusLabel {
	white-space: nowrap;
	display: flex;
	//flex-wrap: wrap;
	align-items: center;
	max-width: 48rem;

	.status, .date{
		font-size: 1.5rem;
		text-transform: uppercase;
		border-radius: .5rem;
	}

	.status {
		padding: .4rem 1rem;

		--label-color: black;
		z-index: 2;
		text-align: center;

		&.status--100 {
			--label-color: #209b48;
		}

		&.status--10 {
			--label-color: #f3af48;
		}

		&.status--99 {
			--label-color: #ec5c5c;
		}

		&.status--0 {
			--label-color: #3373b8;
		}

		&.status--98 {
			--label-color: #340718;
		}

		background-color: var(--label-color);
		color: white;
	}

	.date {
		padding: .3rem 1rem .3rem 1.5rem;
		margin-left: -1rem;
		background-color: #ffffff;
		border: 1px solid lightgrey;
		color: #333333;

		position: relative;
		text-align: center;
	}
	.user {
		color: #333333;
		font-weight: 300;
		margin-left: 0.8rem;
		font-size: 1.3rem;
		text-transform: none;

		.name {
			font-weight: 400;
		}
	}
}
